<template>
  <div class="menu-example__container">
    <h3 class="menu-example__title">
      Menú general:
      <span class="menu-example__title--grey"
        >Puedes crear hasta 5 menús de contenidos</span
      >
    </h3>
    <div class="menu-example__container-menu">
      <div class="menu-example__container-menu--cuadrado">
        <p class="menu-example__text-menu">Menú 1</p>
        <div class="menu-example__dibujo-container">
          <div class="menu-example__dibujo-uno"></div>
        </div>
      </div>
      <div class="menu-example__container-menu--cuadrado">
        <p class="menu-example__text-menu">Menú 2</p>
      </div>
      <div class="menu-example__container-menu--cuadrado">
        <p class="menu-example__text-menu">Menú 3</p>
      </div>
      <div class="menu-example__container-menu--cuadrado">
        <p class="menu-example__text-menu">Menú 4</p>
      </div>
      <div class="menu-example__container-menu--cuadrado">
        <p class="menu-example__text-menu">Menú 5</p>
      </div>
    </div>
    <div class="menu-example__texto-descriptivo">
      Cada menú puede tener 3 niveles de menú
    </div>
    <div class="menu-example__element-wrapper">
      <div class="menu-example__menu-wrapper">
        <p class="menu-example__menu-primario">Menú Primario</p>
        <div class="menu-example__dibujo-container">
          <div class="menu-example__dibujo-dos">
            <div class="menu-example__dibujo-2"></div>
          </div>
        </div>
        <div
          class="menu-example__elemento-container"
          v-for="(elemento, i) in menu_primario"
          :key="i"
        >
          <p class="menu-example__elemento-text">{{ elemento }}</p>
        </div>
      </div>
      <div class="menu-example__menu-wrapper">
        <p class="menu-example__menu-secundario">Menú Secundario</p>
        <div
          class="menu-example__elemento-container--secundario"
          v-for="(elemento, i) in menu_secundario"
          :key="i"
        >
          <p class="menu-example__elemento-text">{{ elemento }}</p>
        </div>
      </div>
      <div class="menu-example__menu-wrapper">
        <p class="menu-example__menu-terciario">Menú Terciario</p>
        <div class="menu-example__dibujo-container">
          <div class="menu-example__dibujo-tres"></div>
        </div>
        <div
          class="menu-example__elemento-container--terciario"
          v-for="(elemento, i) in menu_terciario"
          :key="i"
        >
          <p class="menu-example__elemento-text">{{ elemento }}</p>
        </div>
      </div>
    </div>
    <div class="menu-example__list-container">
      <ul class="menu-example__list-ul">
        <li class="menu-example__list-li">
          <span class="menu-example__list-li--grey">En el </span>
          <strong>Menú Primario: </strong>
          <span class="menu-example__list-li--grey"
            >Puedes habilitar hasta <strong>15 elementos.</strong></span
          >
        </li>
        <li class="menu-example__list-li">
          <span class="menu-example__list-li--grey">En el </span>
          <strong>Menú Secundario: </strong>
          <span class="menu-example__list-li--grey"
            >Puedes habilitar hasta <strong>5 elementos.</strong></span
          >
        </li>
        <li class="menu-example__list-li">
          <span class="menu-example__list-li--grey">En el </span>
          <strong>Menú Terciario: </strong>
          <span class="menu-example__list-li--grey"
            >Puedes habilitar hasta <strong>3 elementos.</strong></span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu_primario: [
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
      ],
      menu_secundario: [
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
        "Elemento",
      ],
      menu_terciario: ["Elemento", "Elemento", "Elemento"],
    };
  },
};
</script>
