<template>
  <section>
    <div
      class="
        personalizacion-intranet__bottom-top
        d-flex
        align-items-center
        flex-wrap
        justify-content-between
      "
    >
      <div class="d-flex align-items-center">
        <h2 class="personalizacion-intranet__bottom-title m-0">
          Aquí podrás crear y configurar el menú de tu Intranet. Ingresa el
          <strong>nombre</strong>, selecciona su <strong>color</strong> y añade
          los <strong>elementos</strong> que lo compondrán.
        </h2>
        <div
          class="personalizacion-intranet__info ms-2"
          @mouseover="info = true"
          @mouseleave="info = false"
        >
          ?
        </div>
      </div>
      <div class="d-flex">
        <button class="personalizacion-intranet__add-button" @click="showModalAddMenu">
          + Crear Menú
        </button>
      </div>
    </div>
    <MenuExample v-show="info == true" />
    <div v-if="menu != 0" class="personalizacion-intranet__menu-sindatos">
      <img
        class="personalizacion-intranet__sindatos-img"
        src="@/assets/img/utilities/question_face.svg"
        alt=""
      />
      <p class="personalizacion-intranet__sindatos-text">
        Aún no has creado un menú
      </p>
      <button class="personalizacion-intranet__sindatos-crear">
        + Crear Menú
      </button>
    </div>
    <div v-if="menu == 0" class="">
      <MenuList />
    </div>
    <CrearMenu v-if="modal_add_menu" @closeModal="closeModal"/>
  </section>
</template>

<script>
import MenuExample from "./MenuExample.vue";
import MenuList from "./MenuList.vue";
import CrearMenu from "./CrearMenu.vue" 
export default {
  components: { MenuExample, MenuList, CrearMenu },
  data() {
    return {
      menu: 0,
      info: false,
      modal_add_menu: false,
    };
  },
  methods: {
    showModalAddMenu() {
      this.modal_add_menu = true;
    },
    closeModal(){
      this.modal_add_menu = false;
    }
  },
};
</script>
