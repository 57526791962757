<template>
  <div class="modal-crear-menu">
    <div class="modal-crear-menu__container">
      <div class="modal-crear-menu__top mt-2">
        <h2 class="modal-crear-menu__title">Crear Menú</h2>
        <font-awesome-icon
          class="modal-crear-menu__close-icon"
          @click="closeModal"
          icon="times"
        ></font-awesome-icon>
        <hr class="m-0" />
      </div>
      <div class="modal-crear-menu__input-container">
        <label class="modal-crear-menu__input-label">
          Nombre Menú
          <input
            class="modal-crear-menu__input-name"
            type="text"
            placeholder="Ingresa nombre de menú"
            v-model="menu_name"
          />
        </label>
      </div>
      <div class="modal-crear-menu__colors-section">
        <p class="modal-crear-menu__colors-title">Selecciona color de Menú</p>
        <div class="modal-crear-menu__colors-container">
          <button
            class="modal-crear-menu__color-circle"
            v-for="(c, i) in colors"
            :key="i"
            :style="{ backgroundColor: c }"
            @click="selectColor(c, i)"
          >
            <font-awesome-icon
              v-show="show_selected === i"
              class="modal-crear-menu__colors-check"
              icon="check"
            ></font-awesome-icon>
          </button>
        </div>
        <hr />
      </div>
      <div class="modal-crear-menu__button-wrapper">
        <button class="modal-crear-menu__cancel-button" @click="closeModal">
          Cancelar
        </button>
        <button class="modal-crear-menu__add-button">Crear</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        "#2c3e50",
        "#6c6cff",
        "#52cec1",
        "#357bf3",
        "#4bbe61",
        "#f6c700",
        "#f26f28",
        "#e64141",
        "#ec206b",
      ],
      show_selected: "",
      selected_color: "",
      menu_name: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    selectColor(color_, index_) {
      this.selected_color = color_;
      this.show_selected = index_;
    },
    addMenu(){
      let color_ = this.selected_color;
      let name_ = this.menu_name;
      this.$emit("closeModal", color_, name_);
    }
  },
};
</script>
