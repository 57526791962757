<template>
  <section class="menu-list__section">
    <div class="menu-list__level-one" v-for="(menu, i) in buscarMenu" :key="i">
      <div class="menu-list__first-container">
        <img
          class="menu-list__drag-img"
          src="@/assets/img/drag_and_drop.svg"
          alt=""
        />
        <div
          class="menu-list__first-menu"
          :style="{ backgroundColor: menu.color_activo }"
        >
          <button
            class="menu-list__show-two"
            @click="showSecond(menu.id_header)"
          >
            <img
              class="menu-list__arrow"
              :class="level_two == menu.id_header ? 'down' : 'right'"
              src="@/assets/img/down_white_arrow.svg"
              alt=""
            />
          </button>
          <div class="d-flex">
            <p class="menu-list__principal-name">{{ menu.nombre_header }}</p>
            <p class="menu-list__elementos">{{menu.menu_elementos.length}} Elementos</p>
          </div>
          <img
            @click="openOption(menu.id_header)"
            class="menu-list__img-actions"
            src="@/assets/img/qiyab.svg"
            alt=""
          />
          <div
            class="menu-list__actions-container"
            v-click-outside="outOption"
            v-if="option == menu.id_header"
          >
            <div class="menu-list__action-wrapper">
              <img
                class="menu-list__action-img"
                src="@/assets/img/Editar.svg"
                alt=""
              />
              <p class="m-0">Editar</p>
            </div>
            <div class="menu-list__action-wrapper">
              <img
                class="menu-list__action-img"
                src="@/assets/img/Eliminar.svg"
                alt=""
              />
              <p class="m-0">Eliminar</p>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="level_two == menu.id_header">
        <div
          class="menu-list__level-two"
          v-for="(m, i) of menu.menu_elementos"
          :key="i"
        >
          <div class="menu-list__second-container">
            <img
              class="menu-list__drag-img"
              src="@/assets/img/drag_and_drop.svg"
              alt=""
            />
            <div
              class="menu-list__second-menu"
              :style="{
                backgroundColor:
                  level_three == m.id_elemento
                    ? m.color_activo
                    : gray_bgs.inactive,
              }"
            >
              <button
                :style="{
                  backgroundColor:
                    level_three == m.id_elemento
                      ? menu.color_activo
                      : gray_bgs.active,
                }"
                class="menu-list__show-three"
                @click="showThird(m.id_elemento)"
              >
                <img
                  class="menu-list__arrow"
                  :class="level_three == m.id_elemento ? 'down' : 'right'"
                  src="@/assets/img/down_white_arrow.svg"
                  alt=""
                />
              </button>
              <input
                class="menu-list__name-input"
                type="text"
                :value="m.nombre_elementos"
                placeholder="Ingresa nombre menú primario"
              />
              <img
                class="menu-list__arrow-right"
                src="@/assets/img/down_arrow_grey.svg"
                alt=""
              />
              <input
                class="menu-list__url-input"
                type="text"
                :value="m.url_menu"
                placeholder="Ingresa link de la página"
              />
              <button class="menu-list__add-elements">+</button> |
              <img
                class="menu-list__img-delete"
                src="@/assets/img/Eliminar.svg"
                alt=""
              />
            </div>
          </div>

          <div class="" v-if="level_three == m.id_elemento">
            <div
              class="menu-list__level-three"
              v-for="(n3, i) in m.levThree"
              :key="i"
            >
              <div class="menu-list__third-container d-flex">
                <img
                  class="menu-list__drag-img"
                  src="@/assets/img/drag_and_drop.svg"
                  alt=""
                />
                <div
                  class="menu-list__third-menu d-flex"
                  :style="{ backgroundColor: n3.color_activo }"
                >
                  <button
                    @click="showFour(n3.id_elemento_fk)"
                    class="menu-list__show-four"
                    :style="{ backgroundColor: menu.color_activo }"
                  >
                    <img
                      class="menu-list__arrow"
                      :class="
                        level_four == n3.id_elemento_fk ? 'down' : 'right'
                      "
                      src="@/assets/img/down_white_arrow.svg"
                      alt=""
                    />
                  </button>
                  <input
                    class="menu-list__name-input"
                    type="text"
                    :value="n3.nombre_relacion"
                    placeholder="Ingresa nombre menú secundario"
                  />
                  <img
                    class="menu-list__arrow-right"
                    src="@/assets/img/down_arrow_grey.svg"
                    alt=""
                  />
                  <input
                    class="menu-list__url-input"
                    type="text"
                    :value="n3.url_menu"
                    placeholder="Ingresa link de la página"
                  />
                  <button class="menu-list__add-elements">+</button> |
                  <img
                    class="menu-list__img-delete"
                    src="@/assets/img/Eliminar.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="" v-if="level_four == n3.id_elemento_fk">
                <div
                  class="menu-list__level-four"
                  v-for="(n4, i) in n3.levFour"
                  :key="i"
                >
                  <div class="menu-list__four-container">
                    <img
                      class="menu-list__drag-img"
                      src="@/assets/img/drag_and_drop.svg"
                      alt=""
                    />
                    <div
                      class="menu-list__four-menu d-flex"
                      :style="{ backgroundColor: n4.color_activo }"
                    >
                      <input
                        class="menu-list__name-input"
                        type="text"
                        :value="n4.nombre_relacion"
                        placeholder="Ingresa link de la página"
                      />
                      <img
                        class="menu-list__arrow-right"
                        src="@/assets/img/down_arrow_grey.svg"
                        alt=""
                      />
                      <input
                        class="menu-list__url-input--level-four"
                        type="text"
                        :value="n4.url_menu"
                        placeholder="Ingresa link de la página"
                      />
                      <button class="menu-list__add-elements">+</button> |
                      <img
                        class="menu-list__img-delete"
                        src="@/assets/img/Eliminar.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="menu-list__add-container"
                v-if="level_four == n3.id_elemento_fk"
              >
                <button
                  class="menu-list__add-button"
                  :style="{
                    borderColor: menu.color_activo,
                    color: menu.color_activo,
                  }"
                >
                  + Agregar nuevo elemento
                </button>
                <p class="menu-list__ my-0">
                  {{ n3.levFour.length }}/3 elementos
                </p>
              </div>
            </div>
          </div>
          <div
            class="menu-list__add-container"
            v-if="level_three == m.id_elemento && level_four == ''"
          >
            <button
              class="menu-list__add-button"
              :style="{
                borderColor: menu.color_activo,
                color: menu.color_activo,
              }"
            >
              + Agregar nuevo elemento
            </button>
            <p class="menu-list__ my-0">{{ m.levThree.length }}/5 elementos</p>
          </div>
        </div>
        <div
          class="menu-list__add-container"
          v-if="level_two == menu.id_header && level_three == ''"
        >
          <button
            class="menu-list__add-button"
            :style="{
              borderColor: menu.color_activo,
              color: menu.color_activo,
            }"
          >
            + Agregar nuevo elemento
          </button>
          <p class="menu-list__ my-0">
            {{ menu.menu_elementos.length }}/15 elementos
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      option: "",
      level_two: "",
      level_three: "",
      level_four: "",
      button_level_two: "",
      button_level_three: "",
      button_level_four: "",
      gray_bgs: {
        active: "#999BA6",
        inactive: "#00000029",
      },
    };
  },
  computed: {
    ...mapState("homeCMSModule", ["buscarMenu"]),
  },
  methods: {
    openOption(id) {
      if (this.option == id) {
        this.outOption;
      } else {
        setTimeout(() => {
          this.option = id;
        }, 10);
      }
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    showSecond(id_) {
      if (this.level_two == "") {
        this.level_two = id_;
        return;
      }
      if (this.level_two != "" && id_ != "") {
        this.level_two = "";
        this.level_three = "";
        this.level_four = "";
      }
    },
    showThird(id_) {
      console.log("show third", this.level_three);
      console.log("show third id", id_);
      if (this.level_three == "") {
        this.level_three = id_;
        return;
      }
      if (this.level_three != "" && id_ != "") {
        this.level_three = "";
        this.level_four = "";
      }
    },
    showFour(id_) {
      if (this.level_four == "") {
        this.level_four = id_;
        return;
      }
      if (this.level_four != "" && id_ != "") {
        this.level_four = "";
      }
    },
  },
};
</script>
